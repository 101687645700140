// ------home page
import { Component, Vue } from "vue-property-decorator";
import news1 from '@/assets/images/news1.png';
import news2 from '@/assets/images/news2.png';
import news3 from '@/assets/images/news3.png';
import ToFooter from "@/components/footer/footer.vue";
import Header from "@/components/header/header.vue";

@Component({
    components: {
        ToFooter,
        Header,
    }
})
export default class Product extends Vue {
    private toptype:number=4;
    private topnumber:number=1;
    private page:number=1;
    private newslist=[
        {
            image:news1,
            id:1,
            title:'联维智慧园区系统化工园区专版—应急环保安全综合管理平台V2.0升级改造完成',
            contect:'根据工信部及中国石油和化学工业联合会化工园区工作委员会发布的《智慧化工园区建设指南》，2020年6月，联维智慧园区系统化工园区专版—应急环保安全综合管理平台V2.0升级改造完成',
            itemshow:false,
            date:'2020-08-27',
        },{
            image:news2,
            id:2,
            title:'智慧仓库管理系统，在中节能 万润C10 新园区仓库建成投入使用',
            contect:'采用先进的物联网技术，引入大数据分析以及二维码跟踪追溯、智能备货补货等机制，结合 AGV机器人小车、红外感应数据采集设备、高速高清晰照相机、机械臂、穿梭提升机、电子称等现代化设备，打造信息系统自动调度，无人值守的智慧仓库管理系统，系统于2019年在中节能 万润C10 新园区仓库建成投入使用。',
            itemshow:false,
            date:'2020-01-05',
        },{
            image:news3,
            id:3,
            title:'非油品中央仓管理平台（WMS）系统，荣获2018年度中石化集团科技创新二等奖',
            contect:'青岛联维软件为山东中石化物流中央仓打造的非油品中央仓管理平台（WMS）系统，荣获2018年度中石化集团科技创新二等奖。',
            itemshow:false,
            date:'2019-07-08',
        },
    ];

    // 左侧菜单跳转
    toPage(index:number) {
        let owner=this;
        owner.toptype=index;
        if(index===1){
            this.$router.push({
                path: "/home",
                query: {}
            });
        }else if(index===2){
            this.$router.push({
                path: "/companyprofile",
                query: {}
            });
        }else if(index===3){
            this.$router.push({
                path: "/product",
                query: {}
            });
        }else if(index===4){
            this.$router.push({
                path: "/news",
                query: {}
            });
        }else if(index===5){
            this.$router.push({
                path: "/mine",
                query: {}
            });
        }
    }
    // 左侧菜单跳转
    totab(index:number) {
        let owner=this;
        owner.topnumber=index;
    }
    inputpage(num:number){
        this.page=num;
        console.log(this.page);
    }
    gotodetail(item:any){
        this.$router.push({
            path: `/newsdetail${item.id}`,
            query: {
                title:item.title
            }
        });
    }
    mouseOver(item:any){
        item.itemshow=true;
    }
    mouseLeave(item:any){
        item.itemshow=false;
    }

    private created() {
        window.scrollTo(0, 0);
    }
}
